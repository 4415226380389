import React, { useRef, useEffect } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { ReportAPI } from "@ai360/core";

import { DateInput, Section, SubSection, SelectInput } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import { getReportDataByReportType, getCropOptionsData } from "~/reports/data/selectors";
import { getSelectedCustomerGuids } from "~/customer-data/selectors";
import { useAppSelector } from "~/store/hooks";
import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";

import { ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";
import { getEventGeneralGuidToEventSummaryMap } from "~/recs-events/events/selectors";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist";

import { keywords } from "../keywords";

type YieldMatrixProps = {
    selectedCrop: ICrop | null;
};

function YieldMatrix({
    intl: { formatMessage },
    onInputChange,
    selectedCrop,
}: ReportOptionsComponentProps & YieldMatrixProps) {
    const crops = useAppSelector(getCropOptionsData);

    const reportData = useAppSelector(getReportDataByReportType(YieldMatrix.reportName));
    const selectedCustomerGuids = useAppSelector(getSelectedCustomerGuids);

    useEffect(() => {
        onInputChange(model.PROPS_CUSTOMER_GUIDS, Array.from(selectedCustomerGuids));
    }, [selectedCustomerGuids.length]);

    const seasons = useAppSelector((state) =>
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        )
    );

    const agEventSummaryMap = useAppSelector(getEventGeneralGuidToEventSummaryMap);
    const selectedEventGuidList = useAppSelector(getSelectedEventGuidSet);
    const seasonsToDisplay = useRef<string[]>();
    const disableOption = useRef<boolean>(false);

    useEffect(() => {
        onInputChange(model.PROPS_SELECTED_EVENT_GUID_LIST, [...selectedEventGuidList]);

        if (selectedEventGuidList.size > 0) {
            const seasonGuids = [];

            for (const eventGeneralGuid of selectedEventGuidList) {
                const event = agEventSummaryMap.get(eventGeneralGuid);
                if (event.agEventTypeName === keywords.harvestEvent) {
                    seasonGuids.push(event.croppingSeasonGuid);
                }
            }
            const seasonsText = [];
            for (const seasonGuid of seasonGuids) {
                const selectedSeason = seasons.find((season) => season.value === seasonGuid);
                if (seasonsText.indexOf(selectedSeason.label) === -1) {
                    seasonsText.push(selectedSeason.label);
                }
            }
            seasonGuids.sort();
            seasonsToDisplay.current = seasonsText;
            disableOption.current = disableReportOptions(selectedEventGuidList);
        } else {
            seasonsToDisplay.current = [];
            disableOption.current = false;
        }
    }, [selectedEventGuidList.size]);

    const disableReportOptions = (selectedEventGuidList: any): boolean => {
        for (const eventGeneralGuid of selectedEventGuidList) {
            const event = agEventSummaryMap.get(eventGeneralGuid);
            if (event.agEventTypeName === keywords.harvestEvent) {
                return true;
            }
        }
    };

    const croppingSeasonDisabled =
        reportData[model.PROPS_START_DATE] ||
        reportData[model.PROPS_END_DATE] ||
        disableOption.current;

    return (
        <div className="report-options">
            <Section>
                <SubSection>
                    <CroppingSeason
                        disabled={croppingSeasonDisabled}
                        required={!croppingSeasonDisabled}
                        reportType={YieldMatrix.reportName}
                        valuesToDisplay={seasonsToDisplay.current}
                    />
                    <SelectInput<ICrop>
                        disabled={disableOption.current}
                        placeholderText={formatMessage(messages.crop)}
                        options={disableOption.current ? [] : crops}
                        onChange={(value) => {
                            onInputChange(model.PROPS_CROP_GUID, value ? value.cropGuid : null);
                        }}
                        value={disableOption.current ? null : (selectedCrop as ICrop)}
                    />
                </SubSection>
                <SubSection>
                    <DateInput
                        placeholderText={formatMessage(messages.startDate)}
                        onChange={(value) => onInputChange(model.PROPS_START_DATE, value)}
                        timeFormat={false}
                        value={reportData[model.PROPS_START_DATE]}
                        disabled={disableOption.current}
                    />
                    <DateInput
                        placeholderText={formatMessage(messages.endDate)}
                        onChange={(value) => onInputChange(model.PROPS_END_DATE, value)}
                        timeFormat={false}
                        value={reportData[model.PROPS_END_DATE]}
                        disabled={disableOption.current}
                    />
                </SubSection>
            </Section>
        </div>
    );
}

YieldMatrix.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
};

YieldMatrix.defaultReportOptions = {
    [model.PROPS_END_DATE]: null,
    [model.PROPS_START_DATE]: null,
    [model.PROPS_CROP_GUID]: null,
    [model.PROPS_SELECTED_EVENT_GUID_LIST]: [],
    [model.PROPS_CROPPING_SEASON_GUID_LIST]: [],
};

YieldMatrix.reportPreferences = [model.PROPS_REPORT_TYPE_PAGE_SELECTION];

YieldMatrix.reportName = ReportAPI.ReportNames.YIELD_MATRIX_REPORT;

export default injectIntl(YieldMatrix) as ReportOptionsComponent<ReportOptionsComponentProps>;
